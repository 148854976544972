//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  async created() {
    console.log(this.requestShopList);
    if (this.requestShopList) return;
    this.setShopList([]);
    const meiCanParams = this.meiCanParams;
    const shopListParams = {
      targetTime: meiCanParams.targetTime,
      externalId: meiCanParams.externalId
    };
    await this.getMenuPromise(shopListParams);
  },
  computed: {
    ...mapGetters(["meiCanParams", "shopList", "requestShopList"])
  },
  mounted() {},
  methods: {
    ...mapMutations(["setShopList", "setRequestShopList"]),
    ...mapActions(["getMenuPromise"]),
    nextHandle(item) {
      console.log(item);
      this.$router.push({
        path: "/takeOutShopDetail",
        query: { restaurantId: item.restaurantId }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    let path = to.path;
    if (path == "/takeOutShopDetail") {
      this.setRequestShopList(true);
    }
    if (path == "/") {
      this.setRequestShopList(false);
    }
    next();
  }
};
